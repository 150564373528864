<template>
  <div class="wrapper">
    <header class="header">
      <div class="row row__top">
        <!-- <div class="langs">
          <button class="langs__button active">LV</button>
          <button class="langs__button">EN</button>
        </div> -->
        <div class="socials">
          <!-- <a class="socials__button button--fb"></a> -->
          <a href="https://www.linkedin.com/in/karlina-sadre-2b947b47/" target="_blank" class="socials__button button--in">LinkedIn link</a>
          <a href="https://www.youtube.com/@intea_elearning" target="_blank" class="socials__button button--yt">YouTube link</a>
          <!-- <a class="socials__button button--ig"></a> -->
        </div>
        <div class="logo-wrapper">
          <div class="logo"></div>
        </div>
        <div class="blocker"></div>
        <!-- <div class="burger-wrapper">
          <button class="button--burger"></button>
        </div> -->
      </div>
      <div class="row row__middle">
        <h1 class="main-tagline">Digital Wine<br>Academy</h1>
        <h4 class="secondary-tagline">Redefining Wine Education in the Digital Era</h4>
      </div>
      <div class="row row__bottom">
        <div class="instruction moving">scroll down</div>
      </div>
    </header>
    <div class="main-page">
      <div class="content-wrapper">
        <div class="content-block">
          <div class="content-block__left content-block__text">
            <div class="decor-element decor-element-moving decor-element__1">
              <img alt="decorative element" src="@/assets/decor-1.png">
            </div>
            <h3 class="content-block__text-header">Our <span class="hl">story</span></h3>
            <p class="content-block__text-body">
              Founded in 2023 by Karlina Sadre, DipWSET, and Raivis Freimanis, DipWSET, Digital Wine Academy,
              is born from our profound love for wine and a joint determination to redefine the learning experience.
              With 15 years of collective experience in the e-learning industry, we embarked on a mission to create
              the first fully e-learning academy of its kind.</p>
          </div>
          <div class="content-block__right content-block__img">
            <div class="content-block__picture">
              <img alt="pic" src="@/assets/content-pic-1.jpg">
            </div>
          </div>
        </div>
        <div class="content-block">
          <div class="content-block__left content-block__img">
            <div class="decor-element decor-element__2">
              <img alt="decorative element" src="@/assets/decor-3.png">
            </div>
            <div class="content-block__picture">
              <img alt="pic" src="@/assets/content-pic-2.jpg">
            </div>
          </div>
          <div class="content-block__right content-block__text">
            <div class="decor-element decor-element-moving decor-element__3">
              <img alt="decorative element" src="@/assets/decor-2.png">
            </div>
            <h3 class="content-block__text-header">Revolutionising<br><span class="hl">Learning</span></h3>
            <p class="content-block__text-body">
              In a landscape where the academic approach to learning is overwhelming and outdated,
              we recognise the need for more tailored solutions. That's why, unlike traditional methods,
              we curate our content to address the <b>specific needs of our customers' learners, ensuring relevance and
              practicality</b> in the business environment. We question the status quo and strive to bridge the gap between
              evolving industry demands and the pace of educational transformation. </p>
          </div>
        </div>
        <div class="content-block">
          <div class="content-block__left content-block__text">
            <div class="decor-element decor-element__4">
              <img alt="decorative element" src="@/assets/decor-1.png">
            </div>
            <h3 class="content-block__text-header">Adult<br><span class="hl">Learning Principles</span></h3>
            <p class="content-block__text-body">
              While we cherish the timeless pleasure of reading a good book, our focus is on active learning.
              Digital Wine Academy is on a mission to redefine the learning experience - making it not just
              informative but also practical and highly effective. Drawing on 15 years of experience in the learning field,
              we've analysed how people learn, identified preferences, and tailored our approach to meet the unique needs of
              wine enthusiasts and industry professionals alike. We believe in providing a <b>holistic learning experience in a digital environment.</b></p>
          </div>
          <div class="content-block__right content-block__img">
            <div class="decor-element decor-element__5">
              <img alt="decorative element" src="@/assets/decor-4.png">
            </div>
            <div class="content-block__picture">
              <img class="no-border" alt="pic" src="@/assets/content-pic-3.svg">
            </div>
          </div>
        </div>
        <div class="content-block">
          <div class="content-block__left content-block__img">
            <div class="content-block__picture">
              <img alt="pic" src="@/assets/content-pic-4.jpg">
            </div>
          </div>
          <div class="content-block__right content-block__text">
            <div class="decor-element decor-element__6">
              <img alt="decorative element" src="@/assets/decor-3.png">
            </div>
            <h3 class="content-block__text-header">Digital <span class="hl">Evolution</span></h3>
            <p class="content-block__text-body">
              Embracing the digital era, Digital Wine Academy offers a <b>cost-effective and engaging e-learning
              platform</b> for wine education. Focusing on businesses seeking tailored solutions, our Academy provides
              a universal yet customizable learning experience. </p>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer">
      <p>
        Contact us for more information<br>
        <a href="mailto:wine@intea-elearning.com">wine@intea-elearning.com</a>
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'MainPage',
  props: {
    msg: String,
  },
  /* eslint-disable */
  mounted() {
    const elements = document.querySelectorAll('.decor-element-moving');
    const instruction = document.querySelector('.instruction');
    window.addEventListener('scroll', () => {
      instruction.classList.remove('moving');
      for (const element of elements) {
        const rect = element.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom >= 0) {
          const scrollPosition = window.scrollY;
          const elementTop = element.offsetTop;
          const distanceFromTop = elementTop - scrollPosition;
          const parallaxValue = distanceFromTop * Math.random() * (0.02 - 0.06) + 0.02;
          element.style.transform = `translateY(${parallaxValue}px)`;
        }
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@use 'sass:math';


$kyw-light-grey: #DCDBDB;
$kyw-white: #FAFAFA;
$kyw-bordo: #4B131D;
$kyw-orange: #C14E19;

@mixin hovering-animation($translateY: -5px, $duration: 3s) {
  animation: hoverUpDown $duration infinite alternate;
  @keyframes hoverUpDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY($translateY);
    }
  }
}

@mixin fluid-type($min-font-size: 12px, $max-font-size: 21px, $lower-range: 420px, $upper-range: 900px) {
  font-size: calc(#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));
  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range){
    font-size: $max-font-size;
  }
}
// mobile()
//   @media (max-width: 768px)
//     {block}
@mixin pcSmall {
  @media (max-width: 1280px) { @content; }
}
@mixin mobile {
  @media (max-width: 1024px) { @content; }
}
@mixin mobileThin {
  @media (max-width: 430px) { @content; }
}

h1, h3, h4 {
  margin: 0px auto;
}

.decor-element {
  position: absolute;
  transition: transform 0.2s ease;
  z-index: 1;
  // &:hover {
  //   transform: translateZ(10px);
  // }
  &__1 {
    left: 5%;
    @include hovering-animation(-10px, 4s);
    @include mobile() {
      left: unset;
      right: -11%;
    }
    // transform: translate(-110px, 0px);
  }
  &__2 {
    left: -10%;
    transform: translateY(180px) scale(0.7);
    @include mobile() {
      left: 0;
      transform: translateY(-90px);
    }
  }
  &__3 {
    right: 5%;
    @include hovering-animation(-5px, 3s);
    @include mobile() {
      display: none;
    }
  }
  &__4 {
    left: 5%;
    @include hovering-animation(-5px, 2s);
    @include mobile() {
      display: none;
    }
  }
  &__5 {
    right: -5%;
    transform: translateY(-140px) scale(0.7);
    @include mobile() {
      right: unset;
      left: 0;
      transform: translateY(-100px);
    }
  }
  &__6 {
    right: -15%;
    transform: translateY(20px) scale(0.7);
    opacity: 0.6;
    @include mobile() {
      right: unset;
      left: 0;
      transform: translateY(-490px);
    }
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header {
  position: relative;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url('@/assets/header-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include mobile() {
    background-image: url('@/assets/header-bg-mobile.png');
  }
  &::after {
    content: '';
    position: absolute;
    background-image: url('@/assets/header-shape.svg');
    background-position: center bottom;
    background-size: 100% auto;
    background-repeat: no-repeat;
    height: 159px;
    width: 100%;
    bottom: -2px;
    left: 0px;
  }
}
.row {
  &__top {
    display: flex;
    justify-content: center;
    // justify-content: space-between;
    padding-top: 25px;
    .socials {
      display: flex;
      margin-left: 50px;
      margin-top: 25px;
      @include mobile() {
        flex: 0 0 33%;
        margin-left: 0px;
        margin-top: 12.5px;
        padding-left: 12.5px;
      }
      &__button {
        height: 46px;
        width: 46px;
        border: none;
        margin: 0px 6px;
        text-indent: 200%;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;
        @include mobile() {
          height: 36px;
          width: 36px;
        }
        &:hover {
          opacity: 0.6;
        }
        &.button--fb {
          background: transparent url('@/assets/fb.svg');
          background-size: contain;
          background-repeat: no-repeat;
        }
        &.button--yt {
          background: transparent url('@/assets/yt.svg');
          background-size: contain;
          background-repeat: no-repeat;
        }
        &.button--in {
          background: transparent url('@/assets/in.svg');
          background-size: contain;
          background-repeat: no-repeat;
        }
        &.button--ig {
          background: transparent url('@/assets/ig.svg');
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }    
    .langs {
      flex: 0 1 10%;
      font-family: 'Roboto';
      &__button {
        border: none;
        background: transparent;
        color: $kyw-light-grey;
        font-weight: 600;
        &.active {
          color: $kyw-white;
        }
      }
    }
    .logo-wrapper {
      flex: 0 0 80%;
      @include mobile() {
        flex: 0 0 33%;
      }
      .logo {
        background: transparent url('@/assets/kyw-logo.svg');
        background-size: contain;
        background-repeat: no-repeat;
        width: 123px;
        height: 120px;
        margin-left: auto;
        margin-right: auto;
        @include mobile() {
          width: 93px;
          height: 91px;
        }
      }
    }
    .burger-wrapper {
      flex: 0 1 10%;
      .button--burger {
        background: transparent url('@/assets/burger.svg');
        height: 24px;
        width: 24px;
        border: none;
        cursor: pointer;
      }
    }
    .blocker {
      width: 232px;
      margin-right: 50px;
      @include mobile() {
        flex: 0 0 33%;
        margin: 0px;
      }
    }  
  }
  &__middle {
    padding-bottom: 10%;
    h1, h4 {
      font-family: 'Rufina';
      color: $kyw-white;
    }
    .main-tagline {
      font-weight: 600;
      // font-size: 120px;
      // line-height: 116px;
      line-height: 1;
      @include fluid-type(59px, 120px, 768px, 1920px);
      @include mobile {
        padding: 0px 80px;
      }
    }
    .secondary-tagline {
      font-weight: 400;
      padding-top: 50px;
      // font-size: 39px;
      // line-height: 116px;
      line-height: 1.2;
      @include fluid-type(21px, 39px, 768px, 1920px);
      @include mobile {
        padding: 0% 23%;
        padding-top: 20px;
      }
    }
  }
  &__bottom {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 7%;
    z-index: 1;
    @include mobile() {
      margin-bottom: 14%;
    }
    .instruction {
      font-family: 'Roboto';
      text-transform: uppercase;
      color: $kyw-light-grey;
      margin: auto;
      &.moving {
        @include hovering-animation(-5px, 3s);
      }
    }
  }
}
.main-page {
  position: relative;
  width: 100vw;
  // height: 2000px;
  background-image: url('@/assets/main-bg.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  @include mobile() {
    padding-top: 40px;
  }
  .content-wrapper {
    max-width: 1920px;
    margin: auto;
  }
  .content-block {
    display: flex;
    justify-content: space-between;
    margin: 0% 14%;
    text-align: left;
    margin-top: 100px;
    margin-bottom: 50px;
    @include pcSmall() {
      margin: 0% 10%;
    }
    @include mobile() {
      margin: 15px 60px;
      margin-bottom: 90px;
      flex-wrap: wrap;
      &__text {
        order: 2;
      }
      &__img {
        order: 1;
        margin-bottom: 30px;
        img {
          width: 100%;
        }
      }
    }
    &__left,
    &__right {
      flex: 0 0 50%;
      @include mobile() {
        flex: 0 0 100%;
      }
    }
    &__left {
      padding-right: 45px;
        @include mobile() {
          padding: 0px;
      }
    }
    &__right {
      padding-left: 45px;
      @include mobile() {
        padding: 0px;
      }
    }
    &__text-header {
      position: relative;
      z-index: 2;
      // font-size: 52px;
      line-height: 1.5;
      font-weight: 400;
      margin-bottom: 30px;
      font-family: 'Rufina';
      color: $kyw-bordo;
      @include fluid-type(42px, 52px, 768px, 1920px);
      @include mobile() {
        margin-bottom: 30px;
      }
      .hl {
        color: $kyw-orange;
      }
    }
    &__text-body {
      position: relative;
      z-index: 2;
      font-size: 26px;
      line-height: 1.5;
      font-weight: 300;
      font-family: 'Spectral';
      // @include fluid-type(21px, 28px, 768px, 1920px);
      color: $kyw-bordo;
      @include mobile() {
        font-size: 21px;
        line-height: 1.4;
      }
      b {
        font-weight: 600;
      }
    }
    &__picture {
      position: relative;
      z-index: 2;
      text-align: center;
      img {
        border: 20px solid #fff;
        box-shadow: 0px 0px 2px $kyw-light-grey;
        max-width: 100%;
        @include mobile() {
          border: 10px solid #fff;
        }
        &.no-border {
          border: 20px solid transparent;
          box-shadow: none;
        }
      }
    }
    &:nth-child(1) {
      margin-top: 50px;
    }
    &:nth-last-child(1) {
      margin-bottom: 100px;
      @include mobile() {
        margin-bottom: 60px;
      }
    }
  }
}
.footer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  width: 100vw;
  height: 200px;
  background-image: url('@/assets/footer-bg.png');
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  @include mobile() {
    align-items: center;
    p {
      margin-top: 60px;
    }
  }
  &::before {
    content: '';
    position: absolute;
    background-image: url('@/assets/footer-shape.svg');
    background-position: center top;
    background-size: 100% auto;
    background-repeat: no-repeat;
    height: 103px;
    width: 100%;
    top: -2px;
    left: 0px;
  }
  p, a {
    color: $kyw-white;
    font-family: 'Roboto';
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
  }
  a {
    text-decoration: underline;
    &:hover,
    &:focus,
    &:active {
      color: $kyw-light-grey;
    }
  }
}
</style>
