<template>
  <MainPage/>
</template>

<script>
import MainPage from './components/MainPage.vue';

export default {
  name: 'App',
  components: {
    MainPage,
  },
};
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Rufina:wght@400;700&family=Spectral:wght@300;400&display=swap');
@import '@/styles/normalize.css';

html {
  box-sizing: border-box;
  overflow-x: hidden;
}
*, *:before, *:after {
  box-sizing: inherit;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
}
</style>
